/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { BtnSmall, DialogBox, Loader } from "../../components";
import {
  getQRCodeByBatchId,
  printQrCodesByBatchId
} from "../../assets/Js/script";
import { toast } from "react-toastify";
import { ddmmyyyyhhmm } from "../../helpers/ddmmyyyyhhmm";
import CustomDropDown from "../../components/CustomDropDown";
import { QRCode } from "react-qrcode-logo";
import { QRCodeSVG } from "qrcode.react";
const papers = [
  { label: "A4", value: "A4", width: "793.7px", height: "1122.9px" },
  { label: "A3", value: "A3", width: "1122.9px", height: "1587.6px" }
];
const Input = ({ value, onChange, disabled }) => {
  return (
    <input
      id="name"
      placeholder="Search"
      autoComplete="off"
      value={value}
      onChange={onChange}
      className="w-full p-2 border-1 outline-none border-solid border-[#8692A650] bg-neutral-10 focus-visible:bg-primary-white font-semibold text-h5 text-heading-false "
      disabled={disabled}
    />
  );
};

const PrintQrConfigModal = ({
  onClose,
  show,
  selectedData,
  parentData,
  isSet,onCompletePrint
}) => {
  const [datas, setDatas] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [paperType, setPaperType] = useState(papers[0]);
  const [padding, setPadding] = useState("10");
  const [margin, setMargin] = useState("10");
  const [cols, setCols] = useState(3);
  const [previewRows, setPreviewRows] = useState(3);
  const [loading, setLoading] = useState(false);

  const previewParentDivRef = useRef();

  const getQrCodes = async () => {
    console.log(selectedData, "selectedData");
    const { response } = await getQRCodeByBatchId(selectedData?.BatchId, isSet);

    if (response?.status === "success") {
      setDatas(response.data.qrCodeData);
      console.log(response.data.qrCodeData, "ppp");
    } else {
      toast(response?.message ?? "Something went wrong");
    }

    console.log(response);
  };

  const downloadHandler = async () => {
    const logo = `${
      isSet
        ? `setCovers/${parentData?.cover}`
        : `books/${parentData?.imageCoverFront}`
    }`;
    setLoading(true);
    const { response } = await printQrCodesByBatchId(
      selectedData?.BatchId,
      paperType,
      cols,
      margin,
      padding,
      isSet,
      logo,
      parentData.title
    );
    onCompletePrint()
    onClose()
    setLoading(false);
  };
  console.log(parentData, "parentData");
  const DetailsStep = () => (
    <div className=" mt-3 max-w-[700px] flex-1">
      <div className="flex">
        <div className="w-1/2">
          <div className="p-3 border border-primary-gray bg-neutral-10">
            <p className="text-h3">Details</p>
            <div className="mt-3 grid grid-cols-2 gap-y-2 gap-x-1">
              {[
                { label: "Batch ID", value: selectedData?.BatchId },
                {
                  label: "Created at",
                  value: ddmmyyyyhhmm(selectedData?.createdAt)
                },
                { label: "Validity", value: selectedData?.validity },
                { label: "Total QR ID’s", value: selectedData?.totalCount },
                {
                  label: "Used QR ID’s",
                  value: selectedData?.totalCount - selectedData?.unUsedCount
                },
                {
                  label: "Available QR ID’s",
                  value: selectedData?.unUsedCount
                }
              ].map((it) => (
                <div>
                  <p>{it.label}</p>
                  <p className="text-thumb">{it.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/2 p-3 pt-0 ">
          <p className="text-thumb">QR Code Preview</p>
          {/* <p className="text-secondary-dark">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut
            vivamus vitae porttitor lacus, semper pretium non auctor. Euismod
            vitae amet id dignissim et blan, sit amet, consectetur adipiscing
            sit amet, consectetur adipiscing elit elit
          </p> */}
          <div className="flex gap-2 p-5">
            <QRCode
              value={`https://www.eprakashak.in`}
              logoImage={`https://${
                process.env.REACT_APP_AWS_BUCKET_NAME
              }.s3.amazonaws.com/${
                isSet
                  ? `setCovers/${parentData?.cover}`
                  : `books/${parentData?.imageCoverFront}`
              }`}
              logoHeight={75}
              logoWidth={37.5}
              // enableCORS={true}
              fgColor="#702963"
              size={150}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const PrintConfigStep = () => {
    return (
      <div className="flex flex-1  ">
        <div className="mt-24 w-1/2 max-w-[300px] pr-5">
          <div>
            <label className="text-secondary-dark text-s">
              Choose Print Configuration
            </label>
            <CustomDropDown
              options={papers}
              onChange={(e) => setPaperType(e[0])}
              values={[paperType]}
            />
          </div>
          <div className="mt-3">
            <label className="text-secondary-dark text-s">No. of columns</label>
            <CustomDropDown
              options={[
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 }
              ]}
              values={[{ value: cols, label: cols }]}
              onChange={(e) => setCols(e[0].value)}
            />
          </div>
          <div className="mt-3">
            <label
              for="name"
              // className="text-heading-false font-semibold text-h5"
              className="text-heading-false"
            >
              Margin <span className="text-tertiary-dark">*</span>
            </label>
            <div className="flex">
              <Input
                value={margin}
                onChange={(e) => setMargin(e.target.value)}
                disabled
              />
              <CustomDropDown
                values={[{ label: "Pixel", value: "pixel" }]}
                options={[{ label: "Pixel", value: "pixel" }]}
                disabled
                style={{ marginLeft: 10, padding: 10 }}
              />
            </div>
          </div>

          <div className="mt-3">
            <label
              for="name"
              // className="text-heading-false font-semibold text-h5"
              className="text-heading-false"
            >
              Padding <span className="text-tertiary-dark">*</span>
            </label>
            <div className="flex">
              <Input
                value={padding}
                onChange={(e) => setPadding(e.target.value)}
                disabled
              />
              <CustomDropDown
                values={[{ label: "Pixel", value: "pixel" }]}
                options={[{ label: "Pixel", value: "pixel" }]}
                disabled
                style={{ marginLeft: 10, padding: 10 }}
              />
            </div>
          </div>
        </div>
        <div className=" p-5   bg-neutral-10">
          <div
            className={` overflow-hidden   bg-primary-white border aspect-[0.714/1]`}
            style={{
              padding: margin + "px",
              gap: padding + "px",
              //   // gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
              //   height: "70vh",
              //   // gridAutoRows: `calc(${50 / cols}vh - ${(cols-1)*10}px )`
              // alignItems:'flex-start',
              // justifyContent:'flex-start'
              height: "70vh",
              display: "grid",
              gridGap: `${padding}px`,
              gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
              alignItems: "start",
              gridAutoRows: "fit-content(1em)"
            }}
            id="preview"
            ref={previewParentDivRef}
          >
            {datas.map((qr, idx) => {
              if (idx + 1 > (cols == 2 ? 4 : 3 ? 12 : 16)) return null;
              return (
                <div
                  className={` aspect-square flex justify-center flex-col items-center bg-primary-white  border border-dashed border-primary-gray`}
                  style={{
                    padding: 10
                    // width:`calc(${50 /cols}vh - ${cols==3?(cols-1)*10:20}px )`,
                    // height:`calc(${70 /4}vh - ${cols==3?(cols-1)*10:20}px )`
                  }}
                >
                  <QRCodeSVG
                    value={`${process.env.REACT_APP_CUSTOMER_PORTAL_DOMAIN}/qr-details?qrId=${qr.id}`}
                    // logoImage={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${parentData?.imageCoverFront}`}
                    logoHeight={50}
                    enableCORS={true}
                    style={{
                      height: "90%"
                    }}
                    // size={50}
                    imageSettings={{
                      src: `https://${
                        process.env.REACT_APP_AWS_BUCKET_NAME
                      }.s3.amazonaws.com/${
                        isSet
                          ? `setCovers/${parentData?.cover}`
                          : `books/${parentData?.imageCoverFront}`
                      }`,
                      height: 70,
                      width: 35
                    }}
                    fgColor="#702963"
                  />
                  <p className="text-[7px] mt-1 text-center">
                    {qr[`QrCodeDetails.QrCodeId`]}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const config = () => {
    if (previewParentDivRef?.current) {
      const { width, height } =
        previewParentDivRef.current.getBoundingClientRect();
      let qrCodesNo = datas.length;
      const contentPrevieHeight = height - 2 * margin;
      const qrCompHeight = 64; //in px

      console.log(height, contentPrevieHeight, "heightpp");

      const rows = Math.ceil(qrCodesNo / cols);
      let previewRows = 0;
      for (
        let i = 0;
        i < contentPrevieHeight;
        i += qrCompHeight + Number(padding)
      ) {
        console.log(i, "iiii");
        previewRows++;
      }
      console.log(previewRows, "pp");
      setPreviewRows(previewRows);
    }
  };
  useEffect(() => {
    if (show) {
      setCurrentStep(1);
      getQrCodes();
    }
  }, [show]);

  useEffect(() => {
    config();
  }, [currentStep, padding, margin, cols]);

  return (
    <>
      {loading ? (
        <Loader show={loading} />
      ) : (
        <DialogBox show={show ?? false}>
          <div className="bg-primary-white flex flex-col justify-between relative ">
            <div className=" h-full flex flex-1 flex-col border-[1.5px] border-solid border-primary-dark-10 p-6  ">
              <h4 className="text-h5 text-tertiary-dark font-bold">
                Qr Codes Print Details
              </h4>
              {/* <p className="text-secondary-dark">
            Choose the validity that you would like to buys for the selected
            book and complete the renewal
          </p> */}

              {currentStep === 1 && <DetailsStep />}
              {currentStep === 2 && <PrintConfigStep />}
              <div className="mt-5 flex">
              <div className="flex-1" />
              
                {currentStep === 1&&selectedData?.IsPrint && (
                  <div className= " mr-5 w-max flex items-end ">
                    <div className='flex items-center justify-end' >


                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C12.6522 18 15.1957 17.0518 17.0711 15.364C18.9464 13.6761 20 11.3869 20 9C20 6.61305 18.9464 4.32387 17.0711 2.63604C15.1957 0.948212 12.6522 0 10 0C7.34784 0 4.8043 0.948212 2.92893 2.63604C1.05357 4.32387 0 6.61305 0 9C0 11.3869 1.05357 13.6761 2.92893 15.364C4.8043 17.0518 7.34784 18 10 18ZM11.1625 7.4115L9.9125 12.7046C9.825 13.0871 9.94875 13.3043 10.2925 13.3043C10.535 13.3043 10.9012 13.2255 11.15 13.0275L11.04 13.4955C10.6812 13.8848 9.89 14.1683 9.20875 14.1683C8.33 14.1683 7.95625 13.6935 8.19875 12.6844L9.12125 8.78288C9.20125 8.45325 9.12875 8.334 8.7625 8.25412L8.19875 8.163L8.30125 7.73438L11.1637 7.4115H11.1625ZM10 6.1875C9.66848 6.1875 9.35054 6.06897 9.11612 5.858C8.8817 5.64702 8.75 5.36087 8.75 5.0625C8.75 4.76413 8.8817 4.47798 9.11612 4.267C9.35054 4.05603 9.66848 3.9375 10 3.9375C10.3315 3.9375 10.6495 4.05603 10.8839 4.267C11.1183 4.47798 11.25 4.76413 11.25 5.0625C11.25 5.36087 11.1183 5.64702 10.8839 5.858C10.6495 6.06897 10.3315 6.1875 10 6.1875Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                    <p className="ml-2 text-[#A93030] text-center">
                      This batch of Qr Code already printed
                    </p>
                    </div>
                  </div>
                )}
                <BtnSmall
                  onClick={() =>
                    currentStep < 2
                      ? setCurrentStep(currentStep + 1)
                      : downloadHandler()
                  }
                >
                  {currentStep === 2 ? "Download" : "Continue"}
                </BtnSmall>
              </div>
            </div>
            <button onClick={onClose} className="absolute top-8 right-8">
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                  fill="black"
                  fillOpacity="0.53"
                />
              </svg>
            </button>
          </div>
        </DialogBox>
      )}
    </>
  );
};

export default PrintQrConfigModal;
