import { data } from "autoprefixer";
import * as API from "../../constant/api";

// const token = JSON.parse(localStorage.getItem("auth"));

export async function handleLogin(data) {
  let response;
  let error;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    email: data.email,
    password: data.password,
    role: "publisher"
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.LOG_IN, requestOptions);
    response = await res.json();
    if (response.status !== "fail") {
      localStorage.setItem("auth", JSON.stringify(response.token));
      localStorage.setItem("user", JSON.stringify(response.data.user));
    }
  } catch (err) {
    error = await err;
  }

  return { response, error };
}

export async function logOut() {
  let error;
  let response;

  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  try {
    const res = await fetch(API.LOG_OUT, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

// export async function uploadBook(book) {
//   let error;
//   let response;
//   const myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   const body = JSON.stringify({ ...book });

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body,
//     redirect: "follow",
//   };

//   try {
//     const res = await fetch(API.UPLOAD_BOOK, requestOptions);
//     response = await res.json();
//   } catch (err) {
//     error = err;
//   }

//   return { response, error };
// }

export async function handleForgotPassword(email) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role: "publisher",
    email
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.FORGOT_PASSWORD, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleResetPassword(password, passwordConfirm, id) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const body = JSON.stringify({
    role: id.split("&")[1],
    password,
    passwordConfirm
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.RESET_PASSWORD}/${id.split("&")[0]}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getProfile() {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function wallet() {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.WALLET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getGraphData(year) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.GRAPH_DATA}?year=${year}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function register(data, checked) {
  let error;
  let response;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", `application/json`);

  const body = JSON.stringify({
    role: "publisher",
    fullName: data?.full_name,
    email: data?.email,
    mobile: data?.phone,
    password: data?.password,
    passwordConfirm: data?.confirm_password,
    registeredName: data?.registered_name,
    registeredAddress: data?.registered_address,
    identifier: data?.identifier,
    brandName: data?.brand_name,
    gstIN: data?.gst,
    // domain: data?.domain,
    website: data?.website,
    tnCAccepted: checked
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.SIGN_UP, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getMyPublication() {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_MY_PUBLICATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllPublishers() {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_ALL_PUBLISHERS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updatePublicationPicture(picture) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("companyPhoto", picture);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.UPDATE_PUBLICATION_PICTURE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updatePersonalProfile(data, tAndc) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  console.log(data, tAndc, "payload");
  const formdata = new FormData();
  formdata.append("photo", data.photo);
  formdata.append("email", data.email);
  formdata.append("fullName", data.fullName);
  formdata.append("mobile", data.mobile);
  if (tAndc) {
    formdata.append("tnCAccepted", tAndc);
  }
  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.UPDATE_PERSONAL_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllBooks(limit, page, validity) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.GET_ALL_BOOKS_BY_PUBLISHER}?limit=${limit}&page=${page}${
        validity && "&validity=" + validity
      }`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllIndividualUsers(searchText) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.INDIVIDUAL_USERS}?searchText=${searchText??''}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllOrganisations(searchText) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.ORGANISATIONS}?searchText=${searchText??''}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllThirdPartySellers() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.THIRD_PARTIES, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addNewIndividualUser(user) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...user
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.INDIVIDUAL_USERS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addNewOrganization(organization) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...organization
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.INDIVIDUAL_USERS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addNewThirdPartySeller(thirdPartySeller) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...thirdPartySeller
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.INDIVIDUAL_USERS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function createIndiviualUser(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  console.log(data, "script-dta");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...data
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.CREATE_INDIVIUAL_USER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function createOrganization(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  console.log(data, "script-dta");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...data
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.CREATE_ORGANIZATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function createThirdPartySeller(data) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      ...data
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.CREATE_THIRD_PARTY_SELLER, requestOptions);

    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addAMember(data) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      role: "publisher",
      ...data
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ADD_NEW_PUBLISHER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function makePrimary(id) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.MAKE_PRIMARY + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteAMember(id) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.DELETE_A_PUBLISHER + id, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllExpiredBooks(sort) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const call =
    sort !== ""
      ? `${API.GET_ALL_EXPIRED_BOOKS}?filterBy=${sort}`
      : API.GET_ALL_EXPIRED_BOOKS;
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(call, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAvailableAccessCodeByBookId(bookId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.GET_AVAILABLE_ACCESS_CODE_BY_BOOK_ID + bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

// export async function getMultipleAccessCodeByMultipleBookId(books) {
//   let error;
//   let response;

//   const myHeaders = new Headers();
//   myHeaders.append("Authorization", `Bearer ${token}`);
//   myHeaders.append("Content-Type", "application/json");

//   const body = JSON.stringify([...books.map(book => ({bookId:book.id, validity: book.validity}))]);

//   const requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body,
//     redirect: "follow",
//   };

//   try {
//     const res = await fetch(API.EXISTING_ACCESS_CODES_BY_BOOK_IDS, requestOptions);
//     response = await res.json();
//   } catch (err) {
//     error = err;
//   }

//   return { response, error };
// }

export async function getAccessCodeByMultipleBookId(bookId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.GET_BOOK_DETAILS_BY_ID + bookId + "/" + "accessCodes",
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function generateUpdateAccessCodes(books) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify([...books]);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GENERATE_UPDATE_ACCESS_CODES, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getBookDetailsById(bookId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.GET_BOOK_DETAILS_BY_ID + bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function getAllBooksInCart(string) {
  let error;
  let response;
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_ALL_BOOKS_IN_CART + string, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllAccessCodes() {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_ACCESS_CODE_CHARGES, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addBooksInCart(books, isAccessId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify(
    books.map((book) => ({
      bookId: book?.parentId,
      validity: book?.validity,
      maxValidity: book?.maxValidity ?? book.validity
    }))
  );
  console.log(body, "hapyyy");
  console.log(isAccessId, "boolen");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.ADD_BOOKS_IN_CART + (isAccessId ? "accessId" : "orderId"),
      requestOptions
    );

    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteAllBooksInCart(books, isAccessId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify(books.map((book) => book.id));

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.REMOVE_ALL_BOOKS_IN_CART + (isAccessId ? "accessId" : "orderId"),
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function removeABookInCart(bookId, isAccessId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.REMOVE_A_BOOK_IN_CART +
        (isAccessId ? "accessId/" : "orderId/") +
        bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function removeValidityCart(cartId, isAccessId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };
  try {
    const res = await fetch(
      API.REMOVE_VALIDITY_IN_CART +
        (isAccessId ? "accessId/validity/" : "orderId/validity/") +
        cartId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function convertBookAccessIdToQr(bookId, validity, count) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    bookId,
    validity,
    count
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.CONVERT_TO_QR, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function updateQuantity(
  cartId,
  numberOfCopies,
  isAccessId,
  qrCodes
) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    numberOfCopies,
    qrCodes
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.UPDATE_QUANTITY + (isAccessId ? "accessId/" : "orderId/") + cartId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateValidity(bookId, numberOfCopies, isAccessId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    validity: numberOfCopies
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.UPDATE_VALIDITY +
        (isAccessId ? "accessId/" : "orderId/") +
        bookId +
        "/validity",
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function updateValidityforSets(
  bookId,
  numberOfCopies,
  isAccessId
) {
  let error;
  let response;

  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    validity: numberOfCopies
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.UPDATE_VALIDITY__FOR_SET +
        (isAccessId ? "accessId/" : "orderId/") +
        bookId +
        "/validity",
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
export async function removeValiditySetCart(cartId, isAccessId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };
  try {
    const res = await fetch(
      API.REMOVE_VALIDITY_IN_SET_CART +
        (isAccessId ? "accessId/validity/" : "orderId/validity/") +
        cartId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function convertSetAccessIdToQr(setId, validity, count) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    setId,
    validity,
    count
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.SET_CONVERT_TO_QR, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
////sets///////////////////

export async function getOrderHistory(type, bookId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.ORDER_HISTORY + type + "=" + bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getSetOrderHistory(type, bookId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.SET_ORDER_HISTORY + type + "=" + bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

/// add a book stepper

export async function addABookStepZero() {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_0_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  console.log(response, "klklkll");
  return { response, error };
}

export async function addABookStepalpha(file, url) {
  let errors;
  let responses;
  let data;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", 'application/pdf');
  console.log(file, "script");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: file,
    redirect: "follow"
  };

  try {
    const res = await fetch(url, requestOptions);
    console.log(res, "resjson");
    responses = true;
    data = res;
  } catch (err) {
    errors = err;
    responses = false;
  }
  // let res2 = await data.json()

  return { responses, errors };
}

export async function addABookStepFirst(key, bookname) {
  let error;
  let responsed;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    key: key,
    title: bookname
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_1_ADD_BOOK, requestOptions);
    responsed = await res.json();
  } catch (err) {
    error = err;
  }

  return { responsed, error };
}
export async function getAdendumTotalPages(bookId) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_ADENDUM_PAGES + bookId, requestOptions);
    response = res;
  } catch (err) {
    error = err;
    console.log(err);
  }
  response = await response.json();
  return { response, error };
}

export async function addAddendumsStepFirst(key, bookname, parentId) {
  let error;
  let responsed;

  console.log(parentId, "parnet id");

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log(parentId, "payLoad");

  const body = JSON.stringify({
    key: key,
    title: bookname,
    parentId: parentId
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_1_ADD_BOOK, requestOptions);
    responsed = await res.json();
  } catch (err) {
    error = err;
  }

  return { responsed, error };
}
export async function addABookStepSecond(data) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log(data, "payload");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_2_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookStepThird(otp) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log(otp, "Payload");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      otp: otp
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_3_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookStepFourth(bookData, color) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const formData = new FormData();
  console.log(bookData.bookCover[0], bookData.bookCover[1], "books");
  // formData.append("bookPublicationIds", bookData.publications);

  formData.append("bookId", bookData.bookId);
  formData.append("imageCoverFront", bookData.bookCover[0]);
  {
    bookData.bookCover.length > 1 &&
      formData.append("imageCoverBack", bookData.bookCover[1]);
  }
  // formData.append("imageCoverBack", bookData.bookCover[1]);
  formData.append("title", bookData.title);
  {
    !(bookData.ISBN === "") && formData.append("ISBN", bookData.ISBN);
  }
  formData.append("shortDescription", bookData.shortDescription);
  formData.append("longDescription", bookData.longDescription);
  {
    color !== ""
      ? formData.append("backgroundColor", color)
      : formData.append("backgroundColor", "#702963");
  }
  // formData.append("backgroundColor", color);
  bookData.authors.forEach((element) => {
    formData.append("authorIds", element.id);
  });
  bookData.category.forEach((element) => {
    formData.append("categoryIds", element.id);
  });
  bookData.publications.forEach((element) => {
    formData.append("bookPublicationIds", element.id);
  });
  formData.append("allowPrint", bookData.isPrintedBookAvailable);

  // if (bookData.isPrintedBookAvailable) {
  //   formData.append("printedBookPrice", bookData.printedBookCost);
  // }
  formData.append("eBookPrice", bookData.eBookPrice);
  formData.append("hardbookPrice", bookData.hardbookPrice);
  formData.append("edition", bookData.edition);
  formData.append("pageNoStartsAt", bookData.pageNoStartsAt);
  if (bookData.additionalField.enable) {
    formData.append("customFieldName", bookData.additionalField.name);
    formData.append("customFieldContents", bookData.additionalField.value);
  }

  //console.log(formData.entries(), 'posted data');

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_4_ADD_BOOK, requestOptions);
    response = await res.text();
  } catch (err) {
    error = err;
  }

  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImMyZDFkNDc0LTdjYTMtNGQ5Mi1hMDg4LTg5MjI4MDJiYjYwZCIsInJvbGUiOiJwdWJsaXNoZXIiLCJpYXQiOjE2NTY2NTIyMzEsImV4cCI6MTY1OTI0NDIzMX0.tb1scfgbXvAcloSHlAZ_HoYnQDruy3aNh1_N1l3QEqI");

  // var formdata = new FormData();
  // formdata.append("bookId", bookData.bookId);
  // formdata.append("imageCoverFront", bookData.bookCover[0], "[PROXY]");
  // formdata.append("imageCoverBack", bookData.bookCover[1], "[PROXY]");
  // formdata.append("title", "My-First-Test");
  // formdata.append("ISBN", "5-86052-434-1");
  // formdata.append("shortDescription", "This is Short Description.");
  // formdata.append("longDescription", "This is very very long description. yahoooooo you are right. This is very very long description. yahoooooo you are right. This is very very long description. yahoooooo you are right. This is very very long description. yahoooooo you are right. ");
  // formdata.append("authorIds", "2f22e68b-e2cc-42ba-9663-788b98d8241c");
  // formdata.append("categoryIds", "f5cda1a7-a461-43d6-9a62-fcd9febb2640");
  // formdata.append("categoryIds", "c4b377f9-2393-4a6e-97a6-c427b1fcd372");
  // formdata.append("bookPublicationIds", "998631fc-ff20-48f3-800e-47dee6780673");
  // formdata.append("printedBookAvailable", "true");
  // formdata.append("printedBookPrice", "600");
  // formdata.append("eBookPrice", "200");
  // formdata.append("edition", "PQD 2001");
  // formdata.append("customFieldName", "My custom Field name");
  // formdata.append("customFieldContents", "This is my Custom field content ");

  // var requestOptions = {
  //   method: 'POST',
  //   headers: myHeaders,
  //   body: formdata,
  //   redirect: 'follow'
  // };

  // fetch("http://3.109.111.62:3000/api/v1/publisher/books/", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));

  return { response, error };
}

export async function addAddendumsStepFourth(bookData) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  // const formData = new FormData();

  // formData.append("bookId", bookData.bookId);
  // formData.append("title", bookData.title);

  const body = JSON.stringify({
    bookId: bookData.bookId,
    title: bookData.title,
    parentPageNo: bookData.parentPageNo
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_4_ADD_ADDENDUMS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookStepFifth(body) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.STEP_5_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

// other add a book stepper related apis

export async function getOngoingConversion(book) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const formData = new FormData();
  formData.append("myBook", book);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.GET_ALL_ONGOING_BOOK_CONVERSION,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookAddCategory(category) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      category
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ADD_CATEGORY_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookAddAuthor(author) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      author
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ADD_AUTHOR_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookAddPublication(publication) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      publication
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ADD_PUBLICATION_ADD_BOOK, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookSearchCategory(category) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.SEARCH_CATEGORY_ADD_BOOK + category,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookSearchAuthor(author) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.SEARCH_AUTHOR_ADD_BOOK + author,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addABookSearchPublication(publication) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.SEARCH_PUBLICATION_ADD_BOOK + publication,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function payForAccessIds(amount) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      payableCredits: amount
    }),
    redirect: "follow"
  };

  // console.log(body)
  try {
    const res = await fetch(API.PAY_FOR_ACCESS_IDS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function payForOrderIds(role, assigned) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      assignedTo: assigned,
      role: role
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.PAY_FOR_ORDER_IDS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

// deleting the profile picture

export async function deleteProfilePic() {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.DELETE_PROFILE_PICTURE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteBusinessProfilePic() {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.DELETE_BUSINESS_PROFILE, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function transactionHistory(page, typeoftran, duration) {
  let response;
  let error;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "get",
    headers: myHeaders,
    redirect: "follow"
  };
  try {
    const res = await fetch(
      `${
        API.TRANSACTION_HISTORY
      }?typesOfTransactions=${typeoftran}&duration=${duration}&page=${page}&limit=${5}`,
      requestOption
    );

    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

// export async function trantransactionHistoryfilter(value) {
//   let error;
//   let response;

//   const myHeaders = new Headers();
//   myHeaders.append("Authorization", `Bearer ${token}`);

//   const requestOption = {
//     method: "get",
//     headers: myHeaders,
//     redirect: "follow",
//   };
//   try {
//     const res = await fetch(
//       value === "View all"
//         ? API.TRANSACTION_HISTORY
//         : API.TRANSACTION_HISTORY + "?typesOfTransactions" + "=" + value,
//       requestOption
//     );
//     response = await res.json();
//   } catch (err) {
//     error = err;
//   }
//   return { response, error };
// }

export async function verifyOtp(Otp, data) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    otp: `${Otp}`
  });

  const requestOption = {
    method: "post",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.CONFIRM_OTP + (data ? "accessId" : "orderId") + "/" + "verify",
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function addCreadits(data) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    amount: data
  });

  const requestOption = {
    method: "post",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ADD_CREDITS, requestOption);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchfororderid(role, text) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    text: `${text}`,
    role: `${role}`
  });

  const requestOption = {
    method: "post",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.SEARCH_ORDERIDBY_NAME, requestOption);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

// settings
export async function getallTickets(page, type, resolutionDate, ticketdate) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.GET_ALL_TICKET}?status=${type}&page=${page}&limit=5&sort=${resolutionDate}&sort=${ticketdate}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllComments(id) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.GET_ALL_COMMENTS}${id}/comment`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function createNewTicket(data) {
  let error;
  let response;

  console.log(data, "payload");
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  let formData = new FormData();

  formData.append("type", data.type);
  formData.append("subject", data.subject);
  formData.append("description", data.description);
  formData.append("toPortalId", data.toportalId);
  formData.append("toPortalName", data.toportalName);
  formData.append("file", data.file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.CREATE_NEW_TICKET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function createNewcomments(comments, id) {
  let error;
  let response;

  console.log(comments, id, "payload");
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const row = JSON.stringify({
    comment: comments
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: row,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.Add_COMMENTS}${id}/comment`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function updateStatus(id, status) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.UPDATE_STATUS}${id}?status=${status}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchPortalId(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.SEARCH_FOR_TICKET}${text}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function handleUpdatePassword(data) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  console.log(data, "payload");
  const body = JSON.stringify({
    currentPassword: data.current_password,
    password: data.new_password,
    passwordConfirm: data.confirm_password
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.UPDATE_PASSWORD, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

// notification

export async function getallNotification() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_ALL_NOTIFICATION, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateNotification(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.UPDATE_NOTIFICATION}${id}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}
//// access ids order ids in sets

export async function addBooksInCartFromsets(sets, isAccessId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify(
    sets.map((set) => ({
      setId: set?.id,
      validity: set?.validity,
      maxValidity: set?.maxValidity ?? set?.validity
    }))
  );

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.ADD_BOOKS_IN_CART_FOR_SETS + (isAccessId ? "accessId" : "orderId"),
      requestOptions
    );

    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function removeABookInCartforsets(bookId, isAccessId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.REMOVE_A_BOOK_IN_CART_FOR_SETS +
        (isAccessId ? "accessId/" : "orderId/") +
        bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function updateQuantityforSets(
  bookId,
  numberOfCopies,
  isAccessId,
  qrCodes
) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify({
    numberOfCopies: numberOfCopies,
    qrCodes
  });

  const requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.UPDATE_QUANTITY_FOR_SETS +
        (isAccessId ? "accessId/" : "orderId/") +
        bookId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function deleteAllBooksInCartforsets(books, isAccessId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", `jwt=${token}`);

  const body = JSON.stringify(books.map((book) => book.id));

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.REMOVE_ALL_BOOKS_IN_CART_FOR_SETS +
        (isAccessId ? "accessId" : "orderId"),
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function payForAccessIdsInSets(amount) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      payableCredits: amount
    }),
    redirect: "follow"
  };

  // console.log(body)
  try {
    const res = await fetch(API.PAY_FOR_ACCESS_IDS_IN_SETS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function payForOrderIdsInsets(role, assigned) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      assignedTo: assigned,
      role: role
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.PAY_FOR_ORDER_IDS_IN_SETS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllBooksInCartforSets(string) {
  let error;
  let response;
  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.GET_ALL_BOOKS_IN_CART_FOR_SETS + string,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function verifyOtpforSets(Otp, data) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const body = JSON.stringify({
    otp: `${Otp}`
  });

  const requestOption = {
    method: "post",
    headers: myHeaders,
    body,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      API.CONFIRM_OTP_FOR_SETS +
        (data ? "accessId" : "orderId") +
        "/" +
        "verify",
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

// all Sets related apis

export async function getAllSets(limit, page) {
  let error;
  let response;

  const myHeaders = new Headers();
  const token = JSON.parse(localStorage.getItem("auth"));

  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  //?limit=${limit}&page=${page}

  try {
    const res = await fetch(
      `${API.GET_ALL_SETS}?limit=${limit}&page=${page}`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function addBooksInSet(arr) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log(arr, "array sleected");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ bookIds: arr }),
    redirect: "follow"
  };

  //?limit=${limit}&page=${page}

  try {
    const res = await fetch(`${API.ADD_BOOKS_IN_SET}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getBooKforSets(limit, page, searchString) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  //?limit=${limit}&page=${page}

  try {
    const res = await fetch(
      `${API.GET_BOOK_FOR_SELECT}?limit=${limit}&page=${page}&search=${
        searchString ?? ""
      }`,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getAllBooksInSet() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  //?limit=${limit}&page=${page}

  try {
    const res = await fetch(`${API.GET_ALL_BOOKS_IN_SET}`, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getSetDetailsById(bookId) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", `jwt=${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_SET_DETAILS + bookId, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function sendOtp(hostingCharge, validitySelected) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      validity: validitySelected,
      credits: hostingCharge * validitySelected
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.SEND_OTP, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function verifyOtpSets(otps) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ otp: otps.join("") }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.VERIFY_OTP_SETS, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function getSetMetadata() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow"
  };

  try {
    const res = await fetch(API.GET_SET_METADATA, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function activateSet(data, color) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  console.log(color, "payload");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      title: data.setName,
      description: data.description,
      bookPublications: [...data.publications.map((item) => item.id)],
      authors: [...data.authors.map((item) => item.id)],
      backgroundColor: color !== "" ? color : "#702963",
      categories: [...data.category.map((item) => item.id)]
    }),
    redirect: "follow"
  };

  try {
    const res = await fetch(API.ACTIVATE_SET, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function uploadSetCover(cover,backCover) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formData = new FormData();
  formData.append("cover", cover);
  if(backCover){
    formData.append("backCover", backCover);

  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.UPLOAD_SET_COVER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchUser(type, searchString) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res =
      type === 0
        ? await fetch(API.SEARCH_INDIVIDUAL_USER + searchString, requestOptions)
        : type === 1
        ? await fetch(
            API.SEARCH_ORGANIZATION_USER + searchString,
            requestOptions
          )
        : await fetch(
            API.SEARCH_THIRDPARTY_USER + searchString,
            requestOptions
          );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function addUser(type, id) {
  let error;
  let response;

  const name =
    type === 0 ? "customerId" : type === 1 ? "organisationId" : "tpId";
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ [name]: id }),
    redirect: "follow"
  };

  try {
    const res =
      type === 0
        ? await fetch(API.ADD_INDIVIDUAL_USER, requestOptions)
        : type === 1
        ? await fetch(API.ADD_ORGANIZATION_USER, requestOptions)
        : await fetch(API.ADD_THIRDPARTY_USER, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

// search book by title and bookid

export async function searchBookbyTitle(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.SEARCH_BOOK_BY_TITLE}${text}`,
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function searchBookbyBookId(text) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      `${API.SEARCH_BOOK_BY_BOOKID}${text}`,
      requestOption
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function deleteDraftBook(id) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOption = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(`${API.DELETE_DRAFT_BOOK}${id}`, requestOption);
    response = await res.json();
  } catch (err) {
    error = err;
  }
  return { response, error };
}

export async function getQrCodeDetails(qrCode) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(API.VALIDATE_QR + qrCode, requestOptions);
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function getQRCodeByBatchId(batchId, isSet) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      (isSet ? API.SET_QR_BY_BATCH_ID : API.QR_BY_BATCH_ID) + batchId,
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}

export async function printQrCodesByBatchId(
  batchId,
  paperType,
  cols,
  margin,
  padding,
  isSet,logo,title
) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      batchId,
      paperType,
      cols,
      margin,
      padding,
      logo
    })
  };

  try {
    const res = await fetch(
      isSet ? API.SET_PRINT_QR_BY_BATCH_ID : API.PRINT_QR_BY_BATCH_ID,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob

        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element for downloading the PDF
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}_${batchId}.pdf`; // Set the desired file name
        a.style.display = "none";

        // Add the anchor to the document and trigger the click event
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  } catch (err) {
    error = err;
  }
  return { response, error };
}


export async function downloadSingleQrCode(
  qrId, qrCode,logo ,title
) {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      qrId, qrCode,logo 
    })
  };

  try {
    const res = await fetch(
    API.PRINT_SINGLE_QR,
      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob

        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element for downloading the PDF
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}_${qrCode}.pdf`; // Set the desired file name
        a.style.display = "none";

        // Add the anchor to the document and trigger the click event
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  } catch (err) {
    error = err;
  }
  return { response, error };
}
export async function getS3SessionToken() {
  let error;
  let response;
  const token = JSON.parse(localStorage.getItem("auth"));

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const res = await fetch(
      (API.GET_S3_SESSION_TOKEN ),
      requestOptions
    );
    response = await res.json();
  } catch (err) {
    error = err;
  }

  return { response, error };
}