import SimpleCrypto from 'simple-crypto-js';


const secretKey = process.env.REACT_APP_ENC_KEY;
const simpleCrypto = new SimpleCrypto(secretKey);
const encryptFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
     const pdfBase64=reader.result.split(',')[1]
     const encryptedPdfBase64 = simpleCrypto.encrypt(pdfBase64);
      resolve(encryptedPdfBase64);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export default encryptFile